import React from 'react';
import ReactECharts from 'echarts-for-react';

const HalfDoughnutChart = () => {

const option = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    bottom: '0%',
    left: 'center'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['30%', '70%'],
      center: ['50%', '60%'],
      // adjust the start and end angle
      startAngle: 180,
      endAngle: 360,
      data: [
        { value: 1048, name: 'Search Engine' },
        { value: 735, name: 'Direct' },
        { value: 580, name: 'Email' },
        { value: 484, name: 'Union Ads' },
        { value: 300, name: 'Video Ads' }
      ]
    }
  ]
};


  return <ReactECharts option={option} style={{ height: '400px', width: '100%' }} />;
};
export default HalfDoughnutChart;