import { Box, Typography } from '@mui/material'
import React from 'react'

function Intro(props) {
  return (
    <Box className='widget_wrapper'>
        <Typography variant='body2'>
            What brings you here today? Please use the navigation below or ask me anything about ChatBot product. 🪄
        </Typography>
    </Box>
  )
}

export default Intro