import React, { useRef, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import * as miserableData from "./Miserables.json";

const LesMiserable = () => {
  const chartRef = useRef(null);
  const [option, setOption] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      // Show loading animation
      chartRef.current.getEchartsInstance().showLoading();

      try {
        // Replace this URL with your actual data URL
        //const response = await fetch('https://echarts.apache.org/examples/data/asset/data/les-miserables.json');

        //const response = await fetch(miserableData);
        //const graph = await response.json();
        const graph=await miserableData;
        // Hide loading animation
        chartRef.current.getEchartsInstance().hideLoading();

        // Update options with fetched data
        const newOption = {
          title: {
            text: 'Les Miserables',
            subtext: 'Circular layout',
            top: 'bottom',
            left: 'right'
          },
          tooltip: {},
         
          legend: [
            {
              bottom: '0%',
             left: 'center',
              data: graph.categories.map((a) => a.name)
            }
          ],
          animationDurationUpdate: 1500,
          animationEasingUpdate: 'quinticInOut',
          series: [
            {
              name: 'Les Miserables',
              type: 'graph',
              layout: 'circular',
              circular: {
                rotateLabel: true
              },
              data: graph.nodes,
              links: graph.links,
              categories: graph.categories,
              roam: true,
              label: {
                position: 'right',
                formatter: '{b}'
              },
              lineStyle: {
                color: 'source',
                curveness: 0.3
              }
            }
          ]
        };

        setOption(newOption);
      } catch (error) {
        console.error('Error fetching graph data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <ReactECharts
      ref={chartRef}
      option={option}
      style={{ height: '600px', width: '100%' }}
    />
  );
};

export default LesMiserable;