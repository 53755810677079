import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

const PanelImageURL = '/data/asset/img/custom-gauge-panel.png';
const AnimationDuration = 1000;
const AnimationDurationUpdate = 1000;
const AnimationEasingUpdate = 'quarticInOut';
const ValOnRadianMax = 370;
const OuterRadius = 20;
const InnerRadius = 20;
const PointerInnerRadius = 20;
const InsidePanelRadius = 240;

function SquareGauge({text,color,bgColor}) {
  const [data, setData] = useState([[1, 356]]);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextSource = [[1, Math.round(Math.random() * ValOnRadianMax)]];
      setData(nextSource);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const convertToPolarPoint = (renderItemParams, radius, radian) => {
    return [
      Math.cos(radian) * radius + renderItemParams.coordSys.cx,
      -Math.sin(radian) * radius + renderItemParams.coordSys.cy
    ];
  };

  const makePointerPoints = (renderItemParams, polarEndRadian) => {
    return [
      convertToPolarPoint(renderItemParams, OuterRadius, polarEndRadian),
      convertToPolarPoint(renderItemParams, OuterRadius, polarEndRadian + Math.PI * 0.03),
      convertToPolarPoint(renderItemParams, PointerInnerRadius, polarEndRadian)
    ];
  };

  const makeText = (valOnRadian) => {
    if (valOnRadian < -10) {
      alert('illegal during val: ' + valOnRadian);
    }
    return ((valOnRadian / ValOnRadianMax) * 100).toFixed(0);
  };

  const renderItem = (params, api) => {
    const valOnRadian = api.value(1);
    const coords = api.coord([api.value(0), valOnRadian]);
    const polarEndRadian = coords[3];
    
    const imageStyle = {
      image: PanelImageURL,
      x: params.coordSys.cx - OuterRadius,
      y: params.coordSys.cy - OuterRadius,
      width: OuterRadius * 2,
      height: OuterRadius * 2,
    };
    

    return {
      type: 'group',
      children: [
        {
          type: 'image',
          style: imageStyle,          
          clipPath: {
            type: 'sector',
            shape: {
              cx: params.coordSys.cx,
              cy: params.coordSys.cy,
              r: OuterRadius,
              r0: InnerRadius,
              startAngle: 0,
              endAngle: -polarEndRadian,
              transition: 'endAngle',
              enterFrom: { endAngle: 0 }
            }
          }
        },
        {
          type: 'image',
          style: imageStyle,
          clipPath: {
            type: 'circle',
            shape: {
              points: makePointerPoints(params, polarEndRadian)
            },
            extra: {
              polarEndRadian: polarEndRadian,
              transition: 'polarEndRadian',
              enterFrom: { polarEndRadian: 0 }
            },
            during: function (apiDuring) {
              apiDuring.setShape(
                'points',
                makePointerPoints(params, apiDuring.getExtra('polarEndRadian'))
              );
            }
          }
        },
        {
          type: 'circle',  
          shape: {
            cx: params.coordSys.cx,
            cy: params.coordSys.cy,
            r: InsidePanelRadius,  
          },
                 
          style: {
            fill: bgColor,         
            shadowBlur: 20,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: 'rgba(76,107,167,0.4)'
          }
        },
        {
            type: 'text',
            style: {
              text: text+' \n',            
              fontSize: 15,
              fontWeight: 700,
              x: params.coordSys.cx,
              y: params.coordSys.cy-25,
              fill: color,
              borderRadius:"10px",
              align: 'center',
              verticalAlign: 'middle',           
              enterFrom: { opacity: 0 }
            }
          
          },
        {
          type: 'text',
          extra: {
            valOnRadian: valOnRadian,
            transition: 'valOnRadian',
            enterFrom: { valOnRadian: 0 }
          },
          style: {
            text: makeText(valOnRadian),          
            fontSize: 50,
            fontWeight: 700,
            x: params.coordSys.cx,
            y: params.coordSys.cy+10,
            fill: color,
            align: 'center',
            verticalAlign: 'middle',      
            enterFrom: { opacity: 0 }
          },
          during: function (apiDuring) {
            apiDuring.setStyle(
              'text',
              makeText(apiDuring.getExtra('valOnRadian'))
            );
          }
        }
      ]
    };
  };

  const option = {
    animationEasing: AnimationEasingUpdate,
    animationDuration: AnimationDuration,
    animationDurationUpdate: AnimationDurationUpdate,
    animationEasingUpdate: AnimationEasingUpdate,
    dataset: {
      source: data
    },
    //tooltip: {},
    angleAxis: {
      type: 'value',
      startAngle: 0,
      show: false,
      min: 0,
      max: ValOnRadianMax
    },
    radiusAxis: {
      type: 'value',
      show: false
    },
    polar: {},
    series: [
      {
        type: 'custom',
        coordinateSystem: 'polar',
        renderItem: renderItem
      }
    ]
  };

  return <div style={{borderRadius:"2px"}}><ReactECharts style={{ height: '149px', width: '100%' }} option={option} echarts={echarts} /></div>;
}

export default SquareGauge;