import React, { useState } from "react";
import ReactECharts from 'echarts-for-react';

const Synchronized = () => {
    const data = {
        // Top-level categories
        main: {
          title: 'Main Categories',
          data: [
            { name: 'Category A', value: 100 },
            { name: 'Category B', value: 200 },
            { name: 'Category C', value: 300 }
          ]
        },
        // Subcategories for Category A
        sub: {
          'Category A': {
            title: 'Subcategories of Category A',
            data: [
              { name: 'Subcategory A1', value: 50 },
              { name: 'Subcategory A2', value: 50 }
            ]
          },
          // Subcategories for Category B
          'Category B': {
            title: 'Subcategories of Category B',
            data: [
              { name: 'Subcategory B1', value: 120 },
              { name: 'Subcategory B2', value: 80 }
            ]
          },
          // Subcategories for Category C
          'Category C': {
            title: 'Subcategories of Category C',
            data: [
              { name: 'Subcategory C1', value: 150 },
              { name: 'Subcategory C2', value: 150 }
            ]
          }
        }
      };
      
        const [chartOption, setChartOption] = useState({
          title: {
            text: data.main.title
          },
          tooltip: {},
          series: [
            {
              type: 'pie',
              data: data.main.data
            }
          ]
        });
      
        const handleChartClick = (params) => {
          const category = params.name;
          if (data.sub[category]) {
            setChartOption({
              title: {
                text: data.sub[category].title
              },
              tooltip: {},
              series: [
                {
                  type: 'pie',
                  data: data.sub[category].data
                }
              ]
            });
          }else{
            setChartOption({
              title: {
                text: data.main.title
              },
              tooltip: {},
              series: [
                {
                  type: 'pie',
                  data: data.main.data
                }
              ]
            });
          }
        };
return  <ReactECharts
option={chartOption}
onEvents={{ click: handleChartClick }}
style={{ height: '400px', width: '100%' }}
/> ;
};
export default Synchronized;