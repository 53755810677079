import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  TreeViewAccodionBox,
  TreeViewListItemBtn,
} from "../Treeview/TreeviewStyledEle";
import {
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Radio,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { RiEBikeFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { hoverVehicle, isLoadingState, selectedVehicleData, selectedVehicleTrailsData, singleVehicle, vehicleSummaryWindow,chatBotSingleVehicleData } from "../../../store/slice/ControlsSlice";
import { MdElectricRickshaw, MdOutlineStickyNote2 } from "react-icons/md";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { FaCar, FaRegCopy, FaTractor, FaTruckFront, FaTruckPickup } from "react-icons/fa6";
import { FaBusAlt } from "react-icons/fa";
import { Auto } from "../../../constants/DualIcons";
import { RxCopy } from "react-icons/rx";
import { useGetVehicleDetailsMutation, useAuditLogMutation } from "../../../store/slice/ApiSlice";
import { ListItemButton } from "@mui/material";

function Vehicle(props) {
  const { buttonIndex, vehiclesItem, index, selectVehicle, selctedVehile, updateVehicleList, regionName, dealerName, customerName, fleetName } =
    props;
  const vehicleRef = useRef(vehiclesItem);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [checkNote,setCheckNote] =useState();
  let userDataLocal = localStorage.getItem('userData');
  let userRoleId = JSON.parse(userDataLocal)?.roleId;
  //Hover Animation
  const onMouseEnter = () => {
    dispatch(hoverVehicle(vehiclesItem));
  };
  const onMouseOverCaptureHandler = () => {
    setCheckNote(null);    
    if(userRoleId===17){
      setCheckNote(vehiclesItem.vehicleId);
    }    
  }
  const onMouseLeaveHandler= () => {
    setCheckNote(null);
  } 
  const onMouseLeave = () => {
    dispatch(hoverVehicle(null));
  };
  const vehicleTypeNew = useSelector((state) => state.controls.vehicleType);


  //get localstorage data
  const userData = JSON.parse(localStorage.getItem("userData"));
  const orgType = userData.userOrgType
  const orgName = userData.orgName;
  const userId = userData.userId;
  const userName = userData.userName;
  const orgId = userData.orgId;
  //get vehicle
  let VehicleType = userData.vehicleType;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };


  let [vehicle, setVehicle] = useState(null);
  const getVehicleType = () => {
    switch (VehicleType) {
      case "TwoWheeler":
        setVehicle(2);
        break;
      case "ThreeWheeler":
        setVehicle(3);
        break;
      case "Truck":
        setVehicle(4);
        break;
      case "Car":
        setVehicle(5);
      break;
      case 'Scv':
        setVehicle(6);
      break;
      case 'Tractor':
        setVehicle(7);
      break;
      case 'Bus':
        setVehicle(9);
      break;
      default:
        setVehicle(2);
    }
    // if(userData.resellerId === 113802){
    //   setVehicle(9);
    // }
  };

  useEffect(() => {
    if (VehicleType) {
      getVehicleType();
    }
  }, [vehicleTypeNew]);
  
  //get selected vehicle
  const selectedVehicle = useSelector( (state) => state.controls.selectedVehicle);
  const date = useSelector((state) => state.controls.treeview.date);
  const trailsTime = useSelector((state) => state.controls.treeview.trailsTime);
  const [ getVehicleDetails, { data: getDetails, isLoading: getDetailsLoading, isSuccess: getVehicleSuccess,},] = useGetVehicleDetailsMutation();
  //Get Charging report
  
  const showVehicleDetails = (id) => {
    dispatch(selectedVehicleData(id));
    getSingleVehicleDetails(id);
  }
  
  const getSingleVehicleDetails = useCallback((id) => {
    let body = {
      roleId: 6,
      sessionOrgId: userData.orgId,
      vehicleId: id,
      logoName: "string",
      strDate: date[0],
      endDate: date[1],
      statusFlag: 5,
      orgId: userData.orgId,
      mins: "1",
      orgType: "2",
      agencyId: 0,
      enLatLng: 0,
      sessionOrgVehicleDisplay: 1,
      startOdometer: 0,
      endOdometer: 0,
      storepack: 0,
      userId: "2",
      viewTrails30sec: 0,
      cngVehicle: true,
      hoursFeed: trailsTime,
      view_trails30sec: 0,
    };

    dispatch(isLoadingState(true));
    dispatch(singleVehicle(body));
    getVehicleDetails(body);
   
  },[date, trailsTime]);

  useEffect(() => {
    if (getVehicleSuccess && orgType === 1) {
      dispatch(selectedVehicleTrailsData(getDetails));

      dispatch(isLoadingState(false));
    }
  }, [getVehicleSuccess]);

  
  useEffect(() => {
    if (date !== null && selectedVehicle !== null && orgType === 1) {   
      getSingleVehicleDetails(selectedVehicle);
    }
  }, [date, trailsTime]);


  //Only For Feet Login
  const vehicleSelect = (data) => {
    if(orgType === 1){
      showVehicleDetails(data.current.id);
    }else{
      selectVehicle(data);
      updateVehicleList();
    }

    auditPayload.message = "Tracking > Map > Treeview > "+userData.orgName+" > "+regionName+" > "+dealerName+" > "+customerName+" > "+fleetName+" > "+vehiclesItem.vehicleName;
    auditPayload.action = "Select";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
  //  auditLog(auditPayload);
  }


  //Copy Vin
  const [targetEle, setTargetEle] = React.useState(null);
  const target = Boolean(targetEle);
  const handleCopyVin = (event) => {
    event.preventDefault();
    setTargetEle(event.currentTarget);
  };
  const handleCopyVinClose = () => {
    setTargetEle(null);
  };

  const [msg, setMsg] = useState("Copy Number");
  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    setMsg("Copied");
    reset();
  };

  const reset = setTimeout(() => {
    setMsg("Copy Number");
    return () => clearTimeout(reset);
  }, 1000);
  
  // onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
  //open summary window
  let summaryWindow = useSelector((state) => state.controls.vehicleSummary)
  let [openWindow, setOpenWindow] = useState(summaryWindow);
  const openSummaryWindow = () => {
    setOpenWindow(!openWindow)
  }
  const openSummaryWindow1 = (vehicleId,vehicleNo) => {
    var payload={
      "vehicleId":vehicleId,
      "vehicleNo":vehicleNo
    }
    dispatch(chatBotSingleVehicleData(payload));
  }
  useEffect(() => {
    dispatch(vehicleSummaryWindow(openWindow));
  },[openWindow])

  return (
    <TreeViewAccodionBox sx={{ zIndex: "999" }}>
      <TreeViewListItemBtn
        sx={{padding:'0px'}}
        primary={"vehicle"}
        onMouseEnter={onMouseOverCaptureHandler}
        onMouseLeave={onMouseLeaveHandler}
        secondaryAction={
          <Stack direction={'row'} spacing={1}>
         { vehiclesItem.vehicleId===checkNote && 
          <Tooltip title='Chatbot'>
             <IconButton size="small" onClick={() => openSummaryWindow1(vehiclesItem.vehicleId,vehiclesItem.vehicleName)}>
               <ChatBubbleOutlineIcon />
             </IconButton>
          </Tooltip>
        }
          <Tooltip arrow title={vehiclesItem?.vehicleStatus} placement="right">
              <IconButton
                color={
                  vehiclesItem.vehicleStatus === "Stop"
                    ? "error"
                    : vehiclesItem.vehicleStatus === "Moving"
                    ? "success"
                    : vehiclesItem.vehicleStatus === "Offline"
                    ? "grey[500]"
                    : vehiclesItem.vehicleStatus === "Charging"
                    ? "warning"
                    : "grey[500]"
                }
                size="small"
                sx={{
                  fill:
                    vehiclesItem.vehicleStatus === "Stop"
                      ? theme.palette.error.main
                      : vehiclesItem.vehicleStatus === "Moving"
                      ? theme.palette.success.main
                      : vehiclesItem.vehicleStatus === "Offline"
                      ? theme.palette.grey[500]
                      : vehiclesItem.vehicleStatus === "Charging"
                      ? theme.palette.warning.main
                      : theme.palette.grey[500],
                }}
              >
                {vehicle === 2 ? <RiEBikeFill />
                : vehicle === 3 ? <Auto />
                : vehicle === 4 ? <FaTruckFront />
                : vehicle === 5 ? <FaCar  />
                : vehicle === 6 ? <FaTruckPickup  />
                : vehicle === 7 ? <FaTractor  />
                : vehicle === 9 && <FaBusAlt />
                }
              </IconButton>
            </Tooltip>
            {
              selectedVehicle  === vehiclesItem.vehicleId && 
              <Tooltip title='Summary'>
                <IconButton size="small" onClick={() => openSummaryWindow()}>
                  <MdOutlineStickyNote2 />
                </IconButton>
              </Tooltip>
            }
          
          </Stack>
        }
        onContextMenu={handleCopyVin}
      >

        <ListItemButton
          onClick={() => vehicleSelect(vehicleRef)}
          buttonIndex={buttonIndex + 80}
          id={vehiclesItem.vehicleId}
          key={index}
          ref={vehicleRef}
          data-status={vehiclesItem.vehicleStatus}
          data-orgName={vehiclesItem.orgName}
          data-fleetIndex={index}
        >
        <ListItemIcon>
          <FormControlLabel
            control={
              <Radio
                size="small"
                checked={
                  orgType === 1 ? selectedVehicle  === vehiclesItem.vehicleId ? true : false : selctedVehile === vehiclesItem.vehicleId ? true : false
                }
                color="secondary"
              />
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction={"row"} spacing={0.5}>
              <Typography>{vehiclesItem.vehicleName}</Typography>
            </Stack>
          }
        />
        </ListItemButton>
        
      </TreeViewListItemBtn>
      <Menu
        id="vin menu"
        anchorEl={targetEle}
        open={target}
        onClose={handleCopyVinClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
            background: theme.palette.background.paper,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "50%",
              left: -5,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <ListItem
          secondaryAction={
            <Tooltip title={msg} arrow placement="top">
              <IconButton
                edge="end"
                aria-label="copy"
                size="small"
                onClick={() => copyText(vehiclesItem.vehicleName)}
                color="warning"
              >
                <RxCopy />
              </IconButton>
            </Tooltip>
          }
          dense={true}
        >
          {vehiclesItem.vehicleName}
        </ListItem>
      </Menu>
    </TreeViewAccodionBox>
  );
}

export default Vehicle;
