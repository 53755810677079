import React, { useRef, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import * as productData from "./product.json";
const SankeyChart = () => {
  const chartRef = useRef(null);
  const [option, setOption] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      // Show loading animation
      chartRef.current.getEchartsInstance().showLoading();

      try {
        // Replace with your actual data URL
        const data = await productData;

        // Hide loading animation
        chartRef.current.getEchartsInstance().hideLoading();

        // Define the chart options
        const newOption = {
          title: {
            text: 'Sankey Diagram'
          },
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
          },
          series: [
            {
              type: 'sankey',
              data: data.nodes,
              links: data.links,
              emphasis: {
                focus: 'adjacency'
              },
              levels: [
                {
                  depth: 0,
                  itemStyle: {
                    color: '#fbb4ae'
                  },
                  lineStyle: {
                    color: 'source',
                    opacity: 0.6
                  }
                },
                {
                  depth: 1,
                  itemStyle: {
                    color: '#b3cde3'
                  },
                  lineStyle: {
                    color: 'source',
                    opacity: 0.6
                  }
                },
                {
                  depth: 2,
                  itemStyle: {
                    color: '#ccebc5'
                  },
                  lineStyle: {
                    color: 'source',
                    opacity: 0.6
                  }
                },
                {
                  depth: 3,
                  itemStyle: {
                    color: '#decbe4'
                  },
                  lineStyle: {
                    color: 'source',
                    opacity: 0.6
                  }
                }
              ],
              lineStyle: {
                curveness: 0.5
              }
            }
          ]
        };

        setOption(newOption);
      } catch (error) {
        console.error('Error fetching Sankey data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <ReactECharts
      ref={chartRef}
      option={option}
      style={{ height: '450px', width: '100%' }}
    />
  );
};

export default SankeyChart;