// src/DrillDownChart.js
import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

const DrillDown = () => {
    const getInitialOption = () => ({
        title: {
          text: 'Main Chart',
          subtext: 'Click to Drill Down',
          left: 'center'
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: ['A', 'B', 'C']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          type: 'bar',
          data: [
            { value: 10, name: 'A', children: [{ value: 1, name: 'A1' }, { value: 2, name: 'A2' }] },
            { value: 20, name: 'B', children: [{ value: 3, name: 'B1' }, { value: 4, name: 'B2' }] },
            { value: 30, name: 'C', children: [{ value: 5, name: 'C1' }, { value: 6, name: 'C2' }] }
          ]
        }]
      });
  const [option, setOption] = useState(getInitialOption());

  const getDrillDownOption = (data) => ({
    title: {
      text: `Drill Down: ${data.name}`,
      left: 'center'
    },
    tooltip: {},
    xAxis: {
      type: 'category',
      data: data.children.map(child => child.name)
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      type: 'bar',
      data: data.children.map(child => ({ value: child.value, name: child.name }))
    }]
  });
  const handleChartClick = (params) => {
    if (params.data && params.data.children) {
      setOption(getDrillDownOption(params.data));
    }else{
        setOption(getInitialOption());
    }
  };

  


  return (
    <div>
      <div style={{ width: '500px', height: '400px' }}>
      <ReactECharts
      option={option}
      onEvents={{
        'click': handleChartClick
      }}
    />
      </div>
    </div>

    
  );
};

export default DrillDown;