import Intro from "./Intro"
import { GetNumber, IntroOptions, ReportList, ReportListDetail, DateSelectList, BatteryHealth, VehicleTrip, CurrentFault, CurrentAlert, MainList, BatteryList, MotorList, BatteryDetailList, MotorDetailList, MotorHealthList } from "./Reports"

const Widgets = (props) => {
    const widgets = [
        //intro
        {
            widgetName:'intro',
            widgetFunc: (props) => <Intro {...props}/>
        },
        {
            widgetName:'IntroOptions',
            widgetFunc: (props) => <IntroOptions {...props}/>
        },
        {
            widgetName:'reportData',
            widgetFunc:(props) => <GetNumber {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'mainList',
            widgetFunc:(props) => <MainList {...props}/>
        },
        {
            widgetName:'reportList',
            widgetFunc:(props) => <ReportList {...props}/>
        },
        {
            widgetName:'reportListDetail',
            widgetFunc:(props) => <ReportListDetail {...props}/>,
            mapStateToProps:['gridData']
        },            
        {
            widgetName:'batteryHealth',
            widgetFunc:(props) => <BatteryHealth {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'dateSelectList',
            widgetFunc:(props) => <DateSelectList {...props}/>
        },
        {
            widgetName:'vehicleTrip',
            widgetFunc:(props) => <VehicleTrip {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'currentFault',
            widgetFunc:(props) => <CurrentFault {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'currentAlert',
            widgetFunc:(props) => <CurrentAlert {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'batteryList',
            widgetFunc:(props) => <BatteryList {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'motorList',
            widgetFunc:(props) => <MotorList {...props}/>,
            mapStateToProps:['gridData']
        }, 
        {
            widgetName:'batteryDetail',
            widgetFunc:(props) => <BatteryDetailList {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'motorDetail',
            widgetFunc:(props) => <MotorDetailList {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'motorHealth',
            widgetFunc:(props) => <MotorHealthList {...props}/>,
            mapStateToProps:['gridData']
        },
    ]
    return widgets
}

export default Widgets