// src/Chart.js
import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

const SyncLine = () => {
  // Initial chart data
  const initialOption = {
    xAxis: {
      type: 'category',
      data: ['A', 'B', 'C'] // Example x-axis categories
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      type: 'line',
      data: [1, 2, 3]
    }]
  };

  const [option, setOption] = useState(initialOption);
  const [status,setStatus]=useState(false);
  const onClickFn = () => {
   
    if(status===false){
        setStatus(true);
        setOption({
            xAxis: {
              type: 'category',
              data: ['A', 'B', 'C']
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: 'Series 1',
                type: 'line',
                data: [1, 2, 3]
              },
              {
                name: 'Series 2',
                type: 'line',
                data: [2, 3, 1]
              },
              {
                name: 'Series 3',
                type: 'line',
                data: [3, 2, 1]
              }
            ]
          });
    }
    else{
        setStatus(false);
        setOption(initialOption)
    }
  };

  return (
    <div>
      <ReactECharts
        option={option}
        style={{ height: '379px', width: '100%' }}
      />
      <button onClick={onClickFn}>Update</button>
    </div>
  );
};

export default SyncLine;