import { Avatar, Box, IconButton, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";

function ChatBotHeader({close}) {

  const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
  return (
    <Box className='chatbot_header'>
        <Stack>
            <Box>
                <ListItem dense
                    secondaryAction={
                        <IconButton size='small' sx={{color:'#fff'}} onClick={close}>
                            <IoClose />
                        </IconButton>
                    }
                >
                    <ListItemAvatar>
                            <Avatar src='https://media.istockphoto.com/id/1957053641/vector/cute-kawaii-robot-character-friendly-chat-bot-assistant-for-online-applications-cartoon.jpg?s=612x612&w=0&k=20&c=Uf7lcu3I_ZNQvjBWxlFenRX7FuG_PKVJ4y1Y11aTZUc='/>
                    </ListItemAvatar>
                    <ListItemText 
                        primary={<Typography variant='body2' sx={{color:'#fff'}}>Chat Bot</Typography>}
                        secondary={<Typography variant='caption' sx={{color:'#fff'}}>{selectedVehicle?.vehicleNo}</Typography>}
                        sx={{color:'#fff'}}
                    />
                </ListItem>
            </Box>
        </Stack>
    </Box>
  )
}

export default ChatBotHeader    