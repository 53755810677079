// App.jsx
import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';

const SyncChart = () => {
  // Define initial chart options
  const initialOptions = {
    title: {
      text: 'Dynamic ECharts Example',
    },
    tooltip: {},
    legend: {
      data: ['Sales'],
    },
    xAxis: {
      data: ['Shirts', 'Cardigans', 'Chiffons', 'Pants', 'Heels', 'Socks'],
    },
    yAxis: {},
    series: [
      {
        name: 'Sales',
        type: 'bar',
        data: [5, 20, 36, 10, 10, 20],
      },
    ],
  };

  // State to hold chart options
  const [options, setOptions] = useState(initialOptions);
  const [status,setStatus] =useState(false);
  // Function to update chart data
  const updateChartData = () => {
    if(status===false){
        setStatus(true);
        setOptions({
            ...options,
            series: [
              {
                ...options.series[0],
                data: [10, 30, 20, 15, 25, 30], // New data
              },
            ],
          });
    }else{
        setStatus(false);
        setOptions(initialOptions);
    }
  };

  return (
    <div>
      <div style={{ width: '500px', height: '379px' }}>
        <ReactECharts option={options} />
      </div>
      <button onClick={updateChartData}>Update Chart Data</button>
    </div>
  );
};

export default SyncChart;