import { createChatBotMessage } from "react-chatbot-kit";
import ChatBotHeader from "./ChatBotHeader";
import React,{useEffect} from 'react'
import UserChat from "./UserChat";
import BotChat from "./BotChat";
import BotAvator from "./BotAvator";
import Widgets from "./widget/WidgetList";
import Intro from "./widget/Intro";
import { useSelector,useDispatch } from "react-redux";
import { chatBotSingleVehicle,chatBotSingleVehicleData } from "../../store/slice/ControlsSlice";

const Configwrap = (close,vehicleNo) => { //firstName
    let name = JSON.parse(localStorage.getItem('userData'));
    let botName = 'Nesh Bot'
    const dispatch = useDispatch();
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    console.log("selectedVehicle:"+selectedVehicle);
    const handleClick = (option) => {
       if(selectedVehicle!=null){
        var payload={
            "vehicleId":selectedVehicle?.vehicleId,
            "vehicleNo":selectedVehicle?.vehicleNo,
            "option":option
            }
            dispatch(chatBotSingleVehicleData(payload));
        }        
    };
    const config = {
        initialMessages: [            
            (selectedVehicle?.vehicleNo!=="" && selectedVehicle?.vehicleNo!==undefined)?"":createChatBotMessage(`Hello there! 👋`),
            (selectedVehicle?.vehicleNo!=="" && selectedVehicle?.vehicleNo!==undefined)?"":createChatBotMessage(`Hi! ${name?.firstName}, Welcome to ${botName}. How can I help you today?. 🪄`)
        ],
        state:{
            gridData:[]
        },
        widgets: Widgets(),
        botName:botName,
        customComponents:{
            header: (props) => <ChatBotHeader {...props} close={close}/>,
            userAvatar:() => false,
            userChatMessage: (props) => false,
            // <UserChat {...props}/>
            botChatMessage:(props) => <BotChat {...props} />,
            botAvatar:(props) =>   false,
        },
    }
    return config;
}

export default Configwrap;  