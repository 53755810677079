import React from 'react';
import ReactECharts from 'echarts-for-react';

// Raw data
const rawData = [
  [100, 302, 301, 334, 390, 330, 320],
  [320, 132, 101, 134, 90, 230, 210],
  [220, 182, 191, 234, 290, 330, 310],
  [150, 212, 201, 154, 190, 330, 410],
  [820, 832, 901, 934, 1290, 1330, 1320]
];

// Compute total data for stacking
const totalData = rawData[0].map((_, i) => 
  rawData.reduce((sum, data) => sum + data[i], 0)
);

// Define series data
const series = [
  'Direct',
  'Mail Ad',
  'Affiliate Ad',
  'Video Ad',
  'Search Engine'
].map((name, sid) => ({
  name,
  type: 'bar',
  stack: 'total',
  barWidth: '60%',
  label: {
    show: true,
    formatter: (params) => `${Math.round(params.value * 1000) / 10}%`
  },
  data: rawData[sid].map((d, did) => totalData[did] <= 0 ? 0 : d / totalData[did])
}));

// Define chart options
const option = {
  legend: {
    selectedMode: false
  },
  grid: {
    left: 100,
    right: 100,
    top: 50,
    bottom: 50
  },
  yAxis: {
    type: 'value'
  },
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  series
};

const StackedBarNormalization = () => {
  return (
    <div style={{ height: '432px', width: '100%' }}>
      <ReactECharts
        option={option}
        style={{ height: '100%', width: '100%' }}
        theme="light"
      />
    </div>
  );
};

export default StackedBarNormalization;