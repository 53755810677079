import React, { useState, useCallback } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import Button from '@mui/material/Button';  
// Define possible positions
const posList = [
  'left',
  'right',
  'top',
  'bottom',
  'inside',
  'insideTop',
  'insideLeft',
  'insideRight',
  'insideBottom',
  'insideTopLeft',
  'insideTopRight',
  'insideBottomLeft',
  'insideBottomRight'
];

const BarLableRotation = () => {
  const [config, setConfig] = useState({
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15
  });

  // Create handler to update the config
  const handleChange = (key, value) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      [key]: value
    }));
  };

  // Create the label options based on the current config
  const labelOption = {
    show: true,
    position: config.position,
    distance: config.distance,
    align: config.align,
    verticalAlign: config.verticalAlign,
    rotate: config.rotate,
    formatter: '{c}  {name|{a}}',
    fontSize: 16,
    rich: {
      name: {}
    }
  };

  // Define the chart options
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: ['Forest', 'Steppe', 'Desert', 'Wetland']
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar', 'stack'] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: ['2020', '2021', '2022', '2023', '2024']
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Forest',
        type: 'bar',
        barGap: 0,
        label: labelOption,
        emphasis: {
          focus: 'series'
        },
        data: [320, 332, 301, 334, 390]
      },
      {
        name: 'Steppe',
        type: 'bar',
        label: labelOption,
        emphasis: {
          focus: 'series'
        },
        data: [220, 182, 191, 234, 290]
      },
      {
        name: 'Desert',
        type: 'bar',
        label: labelOption,
        emphasis: {
          focus: 'series'
        },
        data: [150, 232, 201, 154, 190]
      },
      {
        name: 'Wetland',
        type: 'bar',
        label: labelOption,
        emphasis: {
          focus: 'series'
        },
        data: [98, 77, 101, 99, 40]
      }
    ]
  };
  const[displayControl,setDisplayControl]=useState(false);
  const clickCntrllr=()=>{
    if(displayControl===false){
        setDisplayControl(true);
    }else{
        setDisplayControl(false);
    } 
  }
  return (
    <div>    
        <div style={{display:displayControl===true?'block':'none'}}> 
      <div>
        <label>
          Rotate:
          <input
            type="number"
            value={config.rotate}
            min={-90}
            max={90}
            onChange={(e) => handleChange('rotate', parseInt(e.target.value))}
          />
        </label>
      </div>
      <div>
        <label>
          Align:
          <select
            value={config.align}
            onChange={(e) => handleChange('align', e.target.value)}
          >
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          Vertical Align:
          <select
            value={config.verticalAlign}
            onChange={(e) => handleChange('verticalAlign', e.target.value)}
          >
            <option value="top">Top</option>
            <option value="middle">Middle</option>
            <option value="bottom">Bottom</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          Position:
          <select
            value={config.position}
            onChange={(e) => handleChange('position', e.target.value)}
          >
            {posList.map(pos => (
              <option key={pos} value={pos}>
                {pos}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div>
        <label>
          Distance:
          <input
            type="number"
            value={config.distance}
            min={0}
            max={100}
            onChange={(e) => handleChange('distance', parseInt(e.target.value))}
          />
        </label>
      </div>
      </div>
      <Button variant="text" onClick={clickCntrllr} >{displayControl===false?'Open':'Close'} Controller</Button>
      <ReactECharts
        option={option}
        style={{ height: 400, width: '100%' }}
        theme="light"
      />
  
    </div>
  );
};

export default BarLableRotation;