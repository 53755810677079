import {
    Alert,
    Box,
    Tabs,
    Typography
} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import {
    StyledPageLayout,
    StyledTab,
    StyledTabBox,
} from "../../theme/StyledEle";
//import { AnalyticsDashboards } from "../Constans";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { AnalyticsDashboardTabs } from "../../Utils/Constans";
import ReactECharts from 'echarts-for-react';
import DoughnutChart from "./DoughnutChart";  
import HalfDoughnutChart from "./HalfDoughnutChart";  
import StackedLine from "./StackedLine";  
import Distrubution from "./Distrubution"; 
import StackedBarNormalization from "./StackedBarNormalization";  
import StackedCloumnChat from "./StackedCloumnChat";  
import BarLableRotation from "./BarLableRotation";  
import BarRace from "./BarRace";  
import EffectScatter from "./EffectScatter";
import AggregateScatter from "./AggregateScatter";
import Boxplot from "./Boxplot";
import LesMiserable from "./LesMiserable";
import SankeyChart from "./SankeyChart";
import SankeyWithSetting from "./SankeyWithSetting";
import SankeyNodeAlignRight from "./SankeyNodeAlignRight";
import GaugeChart from "./GaugeChart";
import StageSpeedGauge from "./StageSpeedGauge";
import SyncChart from "./SyncChart";
import Synchronized from "./Synchronized";
import DrillDown from "./DrillDown";
import SyncLine from "./SyncLine";
import SquareGauge from "./SquareGauge";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Toolbar from "@mui/material/Toolbar";
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Button from '@mui/material/Button';


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box >{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  

  export const Echarts = () => {
    const [showDashboard, setShowDashboard] = useState("-1");
    const [showCalendar, setShowCalendar] = useState(false);
    const [value, setValue] = React.useState(55);

    let userData = JSON.parse(localStorage.getItem("userData"));
    let RoleId = userData?.roleId;
    let orgType = userData?.userOrgType;
    let resellerId = userData?.resellerId;
    let orgId = userData?.orgId;
    let userId = userData?.id;
    let orgName= userData?.orgName;
    let userName= userData?.userName;
  
  
    const [auditLog] = useAuditLogMutation();
    let auditPayload = {
      //Audit Common Payload
      orgId: orgId,
      userId: userId, 
      orgName: orgName, 
      userName: userName,
    };
  
    useEffect(() => {
      if (showDashboard === 55) {
        setShowCalendar(false);
      }
    }, [showDashboard]);
  
    const handleChange = async (event, newValue) => {
      setValue(newValue);
      if (newValue === 56) {
        setShowCalendar(true);
        auditPayload.message = "Dashboard > Analytics Dashboard > Trip Summary";
      } else {
        setShowCalendar(false);
        auditPayload.message = "Dashboard > Analytics Dashboard >Last Connected";
      }
  
      auditPayload.action = "Select";
      auditPayload.featureName = "Dashboard";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    };
  
    useEffect(async () => {
      auditPayload.message = "Dashboard > Analytics Dashboard";
      auditPayload.action = "View";
      auditPayload.featureName = "Dashboard";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    }, []);
   
    //onsubmit
    let [formData, setFormData] = useState(null);
    const [date, setDate] = useState({ start: null, end: null });
    let [lastConnectUrl, setLastConnectUrl] = useState(null);
    let [vehicleSumUrl, setVehicleSumUrl] = useState(null);
    let [vehicle, setVehicle] = useState(null);
    let iframeReload = useRef();
    //Hide & Show Tabs
    const tabsOpt = useSelector((state) => state.controls.customizer.currentPage.buttonOpt);
    const onSubmitForm = (data) => {
      setFormData(data);
      setDate({ start: data?.fromDate, end: data?.toDate });
      if(iframeReload?.current?.src !== undefined && iframeReload?.current !== null){
        iframeReload.current.src = iframeReload?.current?.src;
      }  
    };
  
    //dashboard urls
    let [lastConnet, setLastConnect] = useState(null);
    let [vehicleSummaryd, setVehicleSummaryd] = useState(null);
    let [oem, setOem] = useState(null);
    const findDashboard = () => {
      //let findUrls = AnalyticsDashboards?.filter( (item) => item?.resellerId === userData?.resellerId);
      let findUrls;
      setLastConnect(findUrls[0]?.dashboardName[0].url);
      setVehicleSummaryd(findUrls[0]?.dashboardName[1].url);
      setOem(findUrls[0]?.oem);
  
    };
  
    useEffect(() => {
        //findDashboard();
    }, []);
  
  
  
    useEffect(() => {
      var regionId = "";
      var customerId = "";
      var dealerId = "";
      var fleetId = "";
      var vehicleId = "";
      let startTime = moment(date.start, "YYYY-MM-DD HH:mm:ss").valueOf();
      let endTime = moment(date.end, "YYYY-MM-DD HH:mm:ss").valueOf();
  
      if (formData !== null) {
        if (formData?.regionId !== "-1" && formData?.regionId !== 0 && orgType !== 5 && orgType !== 3 && orgType !== 1) {
          regionId = '"REGION_ID"=' + formData?.regionId + "and";
        } else {
          regionId = oem && orgType !== 1 ?  '"REGION_ID"!=5' + "and" : ""
        }
  
        if (formData?.dealerId !== "-1" && orgType !== 3 && orgType !== 1) {
          dealerId = '"DEALER_ID"=' + formData?.dealerId + "and";
        } else {
          dealerId = "";
        }
  
        if (formData?.customerId !== "-1" && orgType !== 1) {
          customerId = '"CUSTOMER_ID"=' + formData?.customerId + "and";
        } else {
          customerId = "";
        }
  
        if (formData?.fleetId !== "-1") {
          fleetId = '"org_id"=' + formData?.fleetId + "and";
        } else {
          fleetId = "";
        }
        if (formData?.vehicleId !== "-1") {
          vehicleId = formData?.vehicleId;
          setVehicle(formData?.vehicleId);
        } else {
          vehicleId = "";
          setVehicle(formData?.vehicleId);
          setValue(55);
        }
      }
      if(showDashboard !== '-1' && showDashboard !== null){
        setLastConnectUrl(
          lastConnet +
            "&var-vehicle_id=" +
            vehicleId +
            "&var-Start_date=" +
            moment().format("YYYY-MM-DD") +
            "&var-End_date=" +
            moment().format("YYYY-MM-DD") +
            "&kiosk"
        );
        setVehicleSumUrl(
            vehicleSummaryd +
            "&from=" +
            startTime +
            "&to=" +
            endTime +
            "&var-vehicle_id=" +
            vehicleId +
            "&var-Start_date=" +
            date.start +
            "&var-End_date=" +
            date.end +
            "&var-Date=" +
            date.start +
            "&kiosk"
        );
      }
     
    }, [formData, showDashboard]);
  
    useEffect(() => {
      if (vehicle !== "-1" && vehicle !== null && vehicle !== 404) {
        setShowDashboard(vehicle);
        if(tabsOpt !== null){
          setValue(tabsOpt[0])
        }
      } 
    }, [vehicle]);
  
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
      const [displayVal,setDisplayVal] =useState(false);

      const handleExport = () => {
       
        document.getElementById("div-content-to-export1").style.removeProperty('display');       
        function fnImp(){
          const elementsToPrint = document.querySelectorAll('.content-to-export1'); // Select multiple elements
          const pdf = new jsPDF('p', 'mm', 'a4'); // Create a new PDF document    
           elementsToPrint.forEach((element, index) => {
            html2canvas(element).then(canvas => {
              const imgData = canvas.toDataURL('image/jpeg');
              const imgWidth = 210; // A4 width in mm
              const imgHeight = canvas.height * imgWidth / canvas.width; // Calculate image height
      
              // Add the image to the PDF
              if (index > 0) {
                pdf.addPage(); // Add a new page for each element
              }
              pdf.addImage(imgData, 'jpeg', 10, 10, imgWidth - 20, imgHeight); // Add image to PDF
      
              // Save PDF after processing all elements
              if (index === elementsToPrint.length - 1) {
                pdf.save('dashboard.pdf'); // Save the PDF
              }
            });
          });
          console.log("came here");
        }
        function callfn(){
          document.getElementById("div-content-to-export1").style.display="none";
        }
        setTimeout(fnImp, 600);
        setTimeout(callfn, 2000);
        
      };
     
      const handleExportUpload = () => {
       
        document.getElementById("div-content-to-export1").style.removeProperty('display');
       
        function fnImp(){
          const elementsToPrint = document.querySelectorAll('.content-to-export1'); // Select multiple elements
          const pdf = new jsPDF('p', 'mm', 'a4'); // Create a new PDF document    
           elementsToPrint.forEach((element, index) => {
            html2canvas(element).then(canvas => {
              const imgData = canvas.toDataURL('image/jpeg');
              const imgWidth = 210; // A4 width in mm
              const imgHeight = canvas.height * imgWidth / canvas.width; // Calculate image height
      
              // Add the image to the PDF
              if (index > 0) {
                pdf.addPage(); // Add a new page for each element
              }
              pdf.addImage(imgData, 'jpeg', 10, 10, imgWidth - 20, imgHeight); // Add image to PDF
      
              // Save PDF after processing all elements
              if (index === elementsToPrint.length - 1) {
                pdf.save('dashboard.pdf'); // Save the PDF

    
                // Save PDF as a Blob
                const pdfBlob = pdf.output('blob');
    
                // Convert Blob to FormData
                const formData = new FormData();
                formData.append('file', pdfBlob, 'dashboard.pdf');
                
              }
            });
          });
          console.log("came here");
        }
        function callfn(){
          document.getElementById("div-content-to-export1").style.display="none";
        }
        setTimeout(fnImp, 600);
        setTimeout(callfn, 2000);
        
      };
    return (   
      <StyledPageLayout  sx={{ width:"100%",height: "100vh",overflow:'auto' }}  >
         <Toolbar/>  
         <div><Button onClick={handleExport} variant="text">Export to PDF</Button></div>
           <Paper className="content-to-export">
           <Accordion defaultExpanded  >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize="large"/>}
              sx={{
                minHeight: '35px !important',
              }}
              aria-controls="panel1-content"
              id="panel1-header"
            >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>          
           <Grid container  >
          <Grid item xs={2.4}>
              <SquareGauge text={'Vehicles'} color={"white"} bgColor={"#39d0bc"} />      
              </Grid> 
              <Grid item xs={2.4}>
              <SquareGauge text={'Moving'} color={"white"} bgColor={"#5dc35c"} />  
              </Grid> 
              <Grid item xs={2.4}>
            <SquareGauge text={'Charging'} color={"white"} bgColor={"#fc9266"} />       
              </Grid> 
              <Grid item xs={2.4}>
            <SquareGauge text={'Stop'} color={"white"} bgColor={"#2f78d2"}/>      
              </Grid> 
              <Grid item xs={2.4}>
              <SquareGauge text={'Offline'} color={"white"} bgColor={"#7c7c7e"}/>      
              </Grid>   
              </Grid> 
              </AccordionDetails>
              </Accordion>
             </Paper>
  
  <Paper className="content-to-export">
      <Accordion defaultExpanded  >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
           sx={{
             minHeight: '35px !important',
           }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >
            <Grid item xs={6}>              
            <Item> <DoughnutChart /> </Item> 
            </Grid>
            <Grid item xs={6}>
            <Item><HalfDoughnutChart />    </Item>  
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
    
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
           sx={{
             minHeight: '35px !important',
           }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

            <Grid item xs={6}>
                <Item><StackedLine /></Item>
            </Grid>  
            <Grid item xs={6}>
                <Item><Distrubution /></Item>
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
      </Paper>
      <div className="content-to-export">
            <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid >

            <Grid item xs={6}>
            <Item><BarLableRotation /></Item>       
            </Grid>
            <Grid item xs={6}>
            <Item><StackedBarNormalization /></Item>       
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
           <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

            <Grid item xs={6}>
            <Item><StackedCloumnChat /></Item>       
            </Grid>
            <Grid item xs={6}>
            <Item><BarRace /></Item>       
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
      </div>
      <div className="content-to-export">
            <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

            <Grid item xs={6}>
            <Item><EffectScatter /></Item>       
            </Grid>
            <Grid item xs={6}>
            <Item><AggregateScatter /></Item>       
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
      
            <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

            <Grid item xs={12}>
            <Item><Boxplot /></Item>       
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
      </div>
      <div className="content-to-export">
            <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

            <Grid item xs={12}>
            <Item><LesMiserable /></Item>       
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
            <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

            <Grid item xs={12}>
            <Item><SankeyChart /></Item>       
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
      </div>
            <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

            <Grid item xs={12}>
            <Item><SankeyWithSetting /></Item>       
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
            <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

            <Grid item xs={6}>
            <Item><GaugeChart /></Item>       
            </Grid>
            <Grid item xs={6}>
            <Item><StageSpeedGauge /></Item>       
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
            <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

             <Grid item xs={6}>
            <Item><SyncChart /></Item>       
            </Grid>
            <Grid item xs={6}>
            <Item><Synchronized /></Item>       
            </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion>
            <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          sx={{
            minHeight: '35px !important',
          }}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >

            <Grid item xs={6}>
            <Item><DrillDown /></Item>       
            </Grid>       
            <Grid item xs={6}>
            <Item><SyncLine /></Item>       
            </Grid>  

            </Grid>
            </AccordionDetails>
      </Accordion>
      
      <div id="div-content-to-export1" style={{display: 'none'}}>
         <Toolbar/>  
           <div className="content-to-export1">
                    
           <Grid container  >
          <Grid item xs={2.4}>
              <SquareGauge text={'Vehicles'} color={"white"} bgColor={"#39d0bc"} />      
              </Grid> 
              <Grid item xs={2.4}>
              <SquareGauge text={'Moving'} color={"white"} bgColor={"#5dc35c"} />  
              </Grid> 
              <Grid item xs={2.4}>
            <SquareGauge text={'Charging'} color={"white"} bgColor={"#fc9266"} />       
              </Grid> 
              <Grid item xs={2.4}>
            <SquareGauge text={'Stop'} color={"white"} bgColor={"#2f78d2"}/>      
              </Grid> 
              <Grid item xs={2.4}>
              <SquareGauge text={'Offline'} color={"white"} bgColor={"#7c7c7e"}/>      
              </Grid>   
          </Grid>      
        <Grid container >
            <Grid item xs={6}>              
            <DoughnutChart /> 
            </Grid>
            <Grid item xs={6}>
            <HalfDoughnutChart /> 
            </Grid>
            </Grid>
      
        <Grid container >
            <Grid item xs={6}>
               <StackedLine />
            </Grid>  
            <Grid item xs={6}>
              <Distrubution />
            </Grid>
            </Grid>
           
      </div>
      <div className="content-to-export1">
           
        <Grid >

            <Grid item xs={6}>
            <BarLableRotation />       
            </Grid>
            <Grid item xs={6}>
            <StackedBarNormalization />      
            </Grid>
            </Grid>
            
        <Grid container >

            <Grid item xs={6}>
            <StackedCloumnChat />  
            </Grid>
            <Grid item xs={6}>
          <BarRace />    
            </Grid>
            </Grid>
            
      </div>
      <div className="content-to-export1">
            
        <Grid container >

            <Grid item xs={6}>
            <EffectScatter />       
            </Grid>
            <Grid item xs={6}>
          <AggregateScatter />    
            </Grid>
            </Grid>
          
        <Grid container >

            <Grid item xs={12}>
          <Boxplot />      
            </Grid>
            </Grid>
           
      </div>
      <div className="content-to-export1">
         
        <Grid container >

            <Grid item xs={12}>
            <LesMiserable />      
            </Grid>
            </Grid>
        
        <Grid container >

            <Grid item xs={12}>
          <SankeyChart />      
            </Grid>
            </Grid>
           
      </div>
      <div className="content-to-export1">
        <Grid container >

            <Grid item xs={12}>
            <SankeyWithSetting />     
            </Grid>
            </Grid>
         
        <Grid container >

            <Grid item xs={6}>
           <GaugeChart />      
            </Grid>
            <Grid item xs={6}>
          <StageSpeedGauge />      
            </Grid>
            </Grid>
            </div>
            <div className="content-to-export1">
        <Grid container >

             <Grid item xs={6}>
           <SyncChart />      
            </Grid>
            <Grid item xs={6}>
          <Synchronized />      
            </Grid>
            </Grid>
           
        <Grid container >

            <Grid item xs={6}>
           <DrillDown />       
            </Grid>       
            <Grid item xs={6}>
            <SyncLine />       
            </Grid>  

            </Grid>
            </div>
      </div>
      
      </StyledPageLayout>

     

      
    
    );
  };
  
  export default Echarts;
  