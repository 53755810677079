import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Avatar } from '@mui/material'


function BotChat(props) {
  return (
  (props?.message!=="" && props?.message!==undefined) && <>
  <div style={{ display: 'flex', alignItems: 'center', paddingBottom:"8px",paddingTop:"9px" }}>
        <Avatar sx={{width:'22px', height:'22px', color:'#fff', fontSize:'14px', fontWeight:700, background:'linear-gradient(45deg, #7646ef, #af0a61)', mr:1}}>N</Avatar>
        <Box sx={{padding:'5px', background:'#0309DE',color:"#ffff", borderRadius:'8px'}}>
        <Typography variant='body2'>{props.message}</Typography>
    </Box> 
    </div>
    </>
  )
}

export default BotChat