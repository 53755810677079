import React, { useState, useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';

// Function to generate random data
const generateRandomData = () => {
  return Array.from({ length: 5 }, () => Math.round(Math.random() * 200));
};

// Define initial chart option
const initialOption = {
  xAxis: {
    max: 'dataMax'
  },
  yAxis: {
    type: 'category',
    data: ['A', 'B', 'C', 'D', 'E'],
    inverse: true,
    animationDuration: 300,
    animationDurationUpdate: 300,
    max: 2 // only the largest 3 bars will be displayed
  },
  series: [
    {
      realtimeSort: true,
      name: 'X',
      type: 'bar',
      data: generateRandomData(),
      label: {
        show: true,
        position: 'right',
        valueAnimation: true
      }
    }
  ],
  legend: {
    show: true
  },
  animationDuration: 0,
  animationDurationUpdate: 3000,
  animationEasing: 'linear',
  animationEasingUpdate: 'linear'
};

const BarRace = () => {
  const [option, setOption] = useState(initialOption);
  const chartRef = useRef(null);

  // Function to update chart data
  const updateChartData = () => {
    const newData = option.series[0].data.map(value => {
      if (Math.random() > 0.9) {
        return value + Math.round(Math.random() * 2000);
      } else {
        return value + Math.round(Math.random() * 200);
      }
    });
    setOption(prevOption => ({
      ...prevOption,
      series: [
        {
          ...prevOption.series[0],
          data: newData
        }
      ]
    }));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateChartData();
    }, 3000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <ReactECharts
        ref={chartRef}
        option={option}
        theme="light"
      />
    </div>
  );
};

export default BarRace;